<template>
  <v-container v-if="consumptionBatches.length > 0 && !loading">
    <v-row dense v-if="!isByproducts">
      <v-col>
        <v-text-field
        v-model="search"
        :label="$t('searchTicketNumber')"
        :error-messages="searchFieldError ? $t('ticketNumberMustBeAnInteger') : null"
        color="black"
        clearable
        @click:clear="searchTicketNumber(true)"
        @keyup.enter="searchTicketNumber(false)"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn class="secondary" @click="searchTicketNumber(false)" :disabled="search === '' || search === undefined || search === null">
          {{ $t('search') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels v-model="openPanel" class="mt-6" flat accordion tile>
          <v-expansion-panel v-for="(consumptionBatch, index) in filteredConsumptionBatches" :key="`batch-${consumptionBatch.consumptionBatchId}`">
            <v-expansion-panel-header
              dense hide-actions active-class="secondary white--text"
              :disabled="consumptionBatch.consumptionMode === ConsumptionMode.WeightBased.value"
              :style="`cursor: ${consumptionBatch.consumptionMode === ConsumptionMode.TicketBased.value ? 'pointer' : 'default'};${consumptionBatch.consumptionMode === ConsumptionMode.WeightBased.value ? 'background-color: #f7f7f7' : ''}`">
              <v-container fluid class="ml-n3">
                <v-row dense>
                  <span class="headline">{{getHeaderForBatch(consumptionBatch)}}</span>
                </v-row>
                <v-row dense>
                  <span class="subtitle-1">{{getConsumedOnText(consumptionBatch)}}</span>
                </v-row>
              </v-container>
              <v-spacer></v-spacer>
              <v-container fluid v-if="batchTotals" style="font-size: 20px;">
                <v-row dense justify="end">
                  <AggregateTotalTable
                  :isActive="index === openPanel"
                  :aggregateTotal="batchTotals[consumptionBatch.consumptionBatchId]"
                  :isByproducts="isByproducts"/>
                </v-row>
              </v-container>
              <Icon
              icon="mdi-backup-restore"
              :tooltipText="getRevertText(index)"
              :disabled="shouldBeDisabled(index)"
              tooltipColor="error"
              :small="false"
              :iconColor="index === openPanel ? 'white' : 'error'"
              @icon-clicked="showConfirmDeleteDialogForUnposting(consumptionBatch, index)"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container fluid>
                <v-row dense justify="end">
                  <v-col v-if="consumptionBatch.consumptionMode === 0">
                    <v-text-field
                    v-model="searches[index]"
                    data-testid="consume-search"
                    single-line
                    color="secondary"
                    append-icon="mdi-magnify"
                    clearable
                    class="mr-6"
                    :label="$t('search')"/>
                  </v-col>
                  <v-col cols="auto" class="mt-4">
                    <Icon
                    v-if="consumptionBatch.consumptionMode === 0"
                    icon="mdi-file-delimited-outline"
                    dataTestId="download-inventory-csv-button"
                    :tooltipText="$t('csv')"
                    :small="false"
                    :elementId="'consumption-batch-csv-button'"
                    margin="mr-2"
                    @icon-clicked="downloadCsv(consumptionBatch)" />
                  </v-col>
                </v-row>
                <v-row v-if="consumptionBatch.consumptionMode === 0">
                  <v-col cols="12">
                    <v-data-table
                    class="mt-4"
                    :items="consumptionBatch.tickets"
                    item-key="ticketId"
                    show-select
                    dense
                    :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
                    :items-per-page="25"
                    v-model="selected[index]"
                    :search="searches[index]"
                    :headers="headers">
                      <template #item.ticketNumber="{ item }">
                        <Icon
                        v-if="item.correctionType !== null"
                        :tooltipText="getCorrectionText(item.correctionType)"
                        dataTestId="consume-ticket-correction-icon"
                        icon="mdi-auto-fix"
                        iconColor="black"
                        tooltipColor="black"
                        margin="mt-n1"
                        />
                        <span>{{item.ticketNumber}}</span>
                      </template>
                      <template #item.extTicketNumber1="{item}">
                        <span>
                          {{ item.extTicketNumber1 !== '' ? item.extTicketNumber1 : '-' }}
                        </span>
                      </template>
                      <template #item.extTicketNumber2="{item}">
                        <span>
                          {{ item.extTicketNumber2 !== '' ? item.extTicketNumber2 : '-' }}
                        </span>
                      </template>
                      <template #item.netWeight="{item}">
                        <span>{{ `${tonStringFromPounds(item.inWeight - item.outWeight - item.defectWeight)}` }}</span>
                      </template>
                      <template #item.weighedOutAt="{ item }">{{
                        utcToLocalDate(item.weighedOutAt, 'L - LT')
                      }}</template>
                      <template #item.product="{ item }">{{
                        item.product
                      }}</template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <Dialog :stateId="dialogId">
            <ConfirmDialog
            v-if="confirmDialog"
            :title="dialogTitle"
            :body="dialogBody"
            :confirmText="$t('revert')"
            color="secondary"
            @cancel="close"
            @confirm="deleteConfirmed"/>
            <TicketConfirmation
            v-if="focusedTicket !== undefined"
            @close="close"
            :isConsumption="true"
            :ticketId="focusedTicket.ticketId"/>
          </Dialog>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
    <v-container class="fill-height" v-else>
      <v-row align="center" justify="center" class="pa-6 ma-6" v-if="filteredConsumptionBatches.length === 0">
        <span class="headline black--text">
          {{$t('noConsumedTickets')}}
        </span>
      </v-row>
      <Loading v-else/>
    </v-container>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { numberWithCommas, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { CorrectionTypes, ContractModes, DeckContentType, ConsumptionMode } from '@/utils/Enumerations.js'
import TicketHeaders from '@/headers/Ticket'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ConsumedTickets',

  props: {
    productIdDictionary: {
      type: Object,
      required: true
    },
    refreshFlag: {
      type: Boolean
    },
    contractMode: {
      type: Object,
      default: undefined
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    AggregateTotalTable: () => import('@/components/ticket/ticket-consumption/AggregateTotalTable.vue'),
    Loading: () => import('@/components/core/Loading.vue'),
    TicketConfirmation: () => import('@/components/ticket/ticket-posting/TicketConfirmation.vue')
  },

  data: () => ({
    dialogId: 'consumed-tickets',
    openPanel: undefined,
    confirmDialog: false,
    loading: false,
    searches: [],
    selected: [],
    search: '',
    focusedBatch: undefined,
    focusedTicket: undefined,
    focusedIndex: undefined,
    ConsumptionMode
  }),

  created () {
    this.refresh()
  },

  computed: {
    ...mapGetters('consumption', ['consumptionBatches']),
    ...mapGetters('product', ['allProducts']),

    searchFieldError () {
      if (this.search === '' || this.search === undefined || this.search === null) { return false }
      return isNaN(Number(this.search))
    },

    headers () { return TicketHeaders.consumedTicketHeaders() },

    isByproducts () {
      return this.contractMode.value === ContractModes[1].value
    },

    batchTotals () {
      if (this.productIdDictionary === undefined) { return undefined }

      const batchTotals = {}
      this.filteredConsumptionBatches.forEach(batch => {
        const totalsForBatch = batch.consumptionMode === 0
          ? batch.tickets.reduce((totals, ticket) => {
            const ticketWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
            totals.weightTotal += ticketWeight
            totals.mbfTotal += ticketWeight * this.productIdDictionary[ticket.product].conversionRateW2V
            return totals
          }, { weightTotal: 0, mbfTotal: 0 })
          : { weightTotal: batch.consumedWeight }

        batchTotals[batch.consumptionBatchId] = {
          weightTons: tonStringFromPounds(totalsForBatch.weightTotal),
          mbf: batch.consumptionMode === 0
            ? numberWithCommas(Math.ceil(totalsForBatch.mbfTotal))
            : 'N/A'
        }
      })

      return batchTotals
    },

    dialogBody () {
      if (this.focusedIndex === undefined || this.focusedBatch === undefined) {
        return ''
      }

      if (this.focusedBatch.consumptionMode === 1) {
        return this.$t('revertConsumptionBody', {
          weight: tonStringFromPounds(this.focusedBatch.consumedWeight)
        })
      }

      const selectedLength = this.selected[this.focusedIndex].length
      const batchLength = this.focusedBatch.tickets.length

      return selectedLength === batchLength ? this.$t('ticketBatchConsumptionBody') : this.$t('ticketConsumptionBody')
    },

    dialogTitle () {
      return this.$t('revertConsumption')
    },

    filteredConsumptionBatches () {
      return this.consumptionBatches.filter(cb => cb.deckContentType === (this.isByproducts ? DeckContentType.Byproducts.value : DeckContentType.Logs.value))
    }
  },

  watch: {
    filteredConsumptionBatches: {
      handler () {
        this.addArraysForTables()
      },
      deep: true,
      immediate: true
    },

    refreshFlag (val) {
      if (val === true) {
        this.refresh()
      }
    },

    openPanel (_, oldVal) {
      this.selected[oldVal] = []
    }
  },

  methods: {
    ...mapActions('consumption', ['downloadConsumptionBatchExport', 'fetchConsumptionBatches', 'revertTickets']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    utcToLocalDate,
    tonStringFromPounds,

    refresh () {
      this.loading = true
      this.fetchConsumptionBatches()
        .finally(() => {
          this.search = ''
          this.loading = false
          const indexOfFirstOpenPanel = this.filteredConsumptionBatches.findIndex(c => c.consumptionMode === ConsumptionMode.TicketBased.value)
          this.openPanel = indexOfFirstOpenPanel !== -1 ? indexOfFirstOpenPanel : undefined
        })
    },

    getConsumedOnText ({ consumedByUser, consumedOn }) {
      return this.$t('consumedOnText', {
        consumedByUser,
        consumedOn: utcToLocalDate(consumedOn)
      })
    },

    showConfirmDeleteDialogForUnposting (batch, index) {
      this.close(false)
      this.focusedBatch = batch
      this.focusedIndex = index
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
      this.confirmDialog = true
    },

    addArraysForTables () {
      this.selected = this.filteredConsumptionBatches.map(_ => [])
      this.searches = this.filteredConsumptionBatches.map(_ => '')
    },

    getHeaderForBatch (batch) {
      const ticketCount = batch.tickets.length
      const deck = batch.deck
      const labelText = batch.label ? `- ${batch.label}` : '- N/A'
      return batch.consumptionMode === 0
        ? `${deck} ${labelText}: ${ticketCount} Ticket${ticketCount > 1 ? 's' : ''}`
        : `${deck} ${labelText}`
    },

    getCorrectionText (correctionType) {
      return `CORRECTED
      ${CorrectionTypes.find(ct => ct.value === correctionType).name}`
    },

    async downloadCsv (consumptionBatch) {
      await this.downloadConsumptionBatchExport(consumptionBatch)
    },

    getRevertText (index) {
      const batch = this.filteredConsumptionBatches[index]

      if (batch.consumptionMode === 1) {
        return this.$t('revert')
      } else {
        return this.selected[index].length > 0 ? this.$t('revertSelected') : this.$t('revertAll')
      }
    },

    async searchTicketNumber (clearSearch) {
      if (clearSearch) {
        this.search = ''
      }

      if (this.search?.trim() === '' || this.search === null || this.search === undefined) {
        return
      }

      const castedSearch = Number(this.search)
      let searchedTicket

      for (let i = 0; i < this.filteredConsumptionBatches.length; i++) {
        const batch = this.filteredConsumptionBatches[i]
        const ticket = batch.tickets.find(t => t.ticketNumber === castedSearch)

        if (ticket) {
          searchedTicket = ticket
          break
        }
      }

      if (searchedTicket === undefined) {
        this.setSnackError(this.$t('noTicketsFound'))
        return
      }

      this.close(false)
      this.focusedTicket = searchedTicket
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw', allowFullscreen: false })
    },

    shouldBeDisabled (index) {
      if (index === 0) return false
      const batch = this.filteredConsumptionBatches[index]

      if (batch.consumptionMode === 0) return false

      const previousBatchIndex = this.consumptionBatches.findIndex(b => b.deckId === batch.deckId && b.consumptionBatchId !== batch.consumptionBatchId)
      return previousBatchIndex < index && previousBatchIndex !== -1
    },

    close (shouldRefresh = false) {
      this.confirmDialog = false
      this.focusedTicket = undefined
      this.closeDialogsAtOrAbove(this.dialogId)

      if (shouldRefresh) {
        this.$emit('trigger-refresh')
      }
    },

    async deleteConfirmed () {
      let ticketIds

      const selectedLength = this.selected[this.focusedIndex].length

      if (selectedLength > 0 && this.focusedBatch.tickets.length !== selectedLength) {
        ticketIds = this.selected[this.focusedIndex].map(t => t.ticketId)
      }

      const results = await this.revertTickets({
        ticketIds: ticketIds,
        consumptionBatchId: this.focusedBatch.consumptionBatchId
      })

      if (this.focusedBatch.consumptionMode === 1) {
        this.setSnack(this.$t('consumptionBatchReverted'))
      } else {
        if (results?.successes && results?.failures) {
          this.setSnack(`Successfully reverted ${results.successes} tickets. ${results.failures} failures.`)
        } else {
          this.setSnack('Successfully reverted tickets.')
        }
      }

      this.close(true)
    }
  }
}
</script>
