import { TractTypeCategory } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '../utils/constants'
export class Tract {
  constructor (tractResponse) {
    this.tractId = tractResponse.tractId
    this.code = tractResponse.code
    this.name = tractResponse.name
    this.acres = tractResponse.acres
    this.cost = tractResponse.cost
    this.advanceBalance = tractResponse.advanceBalance
    this.type = tractResponse.type
    this.typeName = tractResponse.type.name
    this.defaultLoggerName = getDefaultLogger(tractResponse.loggers)
    this.category = TractTypeCategory.fromInt(tractResponse.type.category)
    this.spot = tractResponse.spot
    this.countrySubdivision = tractResponse.spot.countrySubdivision
    this.countrySecondarySubdivision = tractResponse.spot.countrySecondarySubdivision
    this.landOwnerAccountName = tractResponse.landOwnerAccountName
    this.landOwnerAccountId = tractResponse.landOwnerAccountId
    this.landownerCount = tractResponse.landownerCount
    this.loggers = tractResponse.loggers
    this.harvestedTons = tractResponse.harvestedTons
    this.expectedTons = tractResponse.expectedTons
    this.businessEntityId = tractResponse.businessEntityId
    this.loggerAccountName = tractResponse.loggerAccountName
    this.loggerAccountId = tractResponse.loggerAccountId
    this.loggerAccountCertificationStatus = tractResponse.loggerAccountCertificationStatus
    this.foresterUser = tractResponse.foresterUser
    this.foresterUserName = tractResponse.foresterUser?.name
    this.foresterAccountCode = tractResponse.foresterAccountCode
    this.haulerAccount = tractResponse.haulerAccount
    this.haulerAccountId = tractResponse.haulerAccountId
    this.supplierAccount = tractResponse.supplierAccount
    this.supplierAccountId = tractResponse.supplierAccountId
    this.contractAccountId = tractResponse.contractAccountId
    this.contractSettingId = tractResponse.contractSettingId
    this.harvestedTons = tractResponse.harvestedTons
    this.expectedTons = tractResponse.expectedTons
    this.harvestPercentage = tractResponse.expectedTons > 0
      ? `${((tractResponse.harvestedTons / tractResponse.expectedTons) * 100).toFixed(2)}%`
      : '0.00%'
    this.harvestedPounds = Math.round(tractResponse.harvestedTons * 2000)
    this.expectedPounds = Math.round(tractResponse.expectedTons * 2000)
    this.tractCertifications = tractResponse.tractCertifications
    this.harvestedString = (tractResponse.expectedTons > 0 && tractResponse.harvestedTons)
      ? `${this.harvestedTons.toFixed(3)} | ${(parseFloat(this.harvestedTons / this.expectedTons) * 100).toFixed(2) + '%'}`
      : 'N/A'
    this.tractContents = tractResponse.tractContents
    this.archivedAt = tractResponse.archivedAt
    this.status = tractResponse.status
    this.harvestEndDate = tractResponse.harvestEndDate
    this.harvestStartDate = tractResponse.harvestStartDate
    this.purchaseDate = tractResponse.purchaseDate
    this.requiresExt1 = tractResponse.requiresExt1
    this.requiresExt2 = tractResponse.requiresExt2
    this.millisecondsTillHarvestEnd = Date.parse(tractResponse.harvestEndDate) - Date.now()
    this.paused = tractResponse.paused
    this.modifiedAt = tractResponse.modifiedAt
    this.consultingForesterAccount = tractResponse.consultingForesterAccount
    this.consultingForesterAccountId = tractResponse.consultingForesterAccountId
    this.loggingAccessibilityTagId = tractResponse.loggingAccessibilityTagId
    this.loggingAccessibilityTag = tractResponse.loggingAccessibilityTag
    this.harvestedTonsPerAcre = (this.harvestedTons / this.acres) < Infinity ? (this.harvestedTons / this.acres).toFixed(3) : 'N/A'
    this.singleDayRainfallInches = tractResponse.rainfallDataUpdatedOn !== null ? tractResponse.singleDayRainfallInches : null
    this.rainfallDataUpdatedOn = tractResponse.rainfallDataUpdatedOn
    this.payables = tractResponse.tractPayables
    this.bondBalance = tractResponse.tractPayables.filter(p => p.performanceBond).map(pb => pb.amount).reduce((acc, pb) => acc + pb, 0)
    this.rpi = tractResponse.rpi
    this.dbh = tractResponse.dbh
    this.age = tractResponse.age
    this.teamName = tractResponse.teamName
    this.teamId = tractResponse.teamId
    this.users = tractResponse.users
  }
}

const getDefaultLogger = (loggers) => {
  const loggerIndex = loggers.findIndex(l => l.isDefault)

  if (loggerIndex === -1) {
    return 'N/A'
  }

  return loggers[loggerIndex].name
}

export class StandingTimber {
  constructor (standingTimber) {
    this.cruisedValue = standingTimber.cruisedValue
    this.tractCost = standingTimber.tractCost
    this.expectedTons = standingTimber.expectedTons
    this.adjustmentTons = standingTimber.adjustmentTons
    this.harvestedTons = standingTimber.harvestedTons
    this.averageSED = standingTimber.averageSED
    this.remainingTons = Math.max(0, standingTimber.remainingTons)
    this.stumpageValue = Math.max(0, standingTimber.stumpageValue)
    this.overcutTons = standingTimber.overcutTons ?? 0

    this.cutoutValue = (this.expectedTons > 0)
      ? standingTimber.cruisedValue * (this.harvestedTons / this.expectedTons - 1)
      : 0

    this.tractId = standingTimber.tractId
    this.tractName = standingTimber.tractName
    this.tractType = standingTimber.tractType
    this.tractForester = standingTimber.tractForester
    this.tractAcres = standingTimber.tractAcres
    this.tractLoggingAccessibility = standingTimber.tractLoggingAccessibility
    this.teamName = standingTimber.teamName
    this.tractUserNames = standingTimber.tractUserNames
  }
}

export class CommittedTimber {
  constructor (committedTimber) {
    this.tractId = committedTimber.tractId
    this.tractName = committedTimber.tractName
    this.tractType = committedTimber.tractType
    this.tractForester = committedTimber.tractForester
    this.tractLoggingAccessibility = committedTimber.tractLoggingAccessibility

    this.expectedTons = committedTimber.expectedTons
    this.harvestedTons = committedTimber.harvestedTons
    this.remainingTons = Math.max(0, committedTimber.remainingTons)
    this.cruisedValue = committedTimber.cruisedValue

    this.rpi = committedTimber.rpi
    this.dbh = committedTimber.dbh
    this.age = committedTimber.age

    this.teamName = committedTimber.teamName
    this.tractUserNames = committedTimber.tractUserNames
  }
}

export class TractContent {
  constructor (serverObject) {
    this.serverObject = serverObject

    // Pounds
    this.adjustmentPounds = serverObject.adjustment
    this.correctedPounds = serverObject.corrected
    this.expectedPounds = serverObject.expected
    this.harvestedPounds = serverObject.harvested + serverObject.corrected
    this.remainingPounds = Math.max(0, serverObject.expected - serverObject.harvested - serverObject.corrected - serverObject.adjustment)

    // Tons
    this.adjustmentTons = parseFloat((this.adjustmentPounds / 2000).toFixed(3))
    this.correctedTons = parseFloat((serverObject.corrected / 2000).toFixed(3))
    this.expectedTons = parseFloat((serverObject.expected / 2000).toFixed(3))
    this.harvestedTons = parseFloat((this.harvestedPounds / 2000).toFixed(3))
    this.remainingTons = parseFloat((this.remainingPounds / 2000).toFixed(3))

    // MBF
    this.adjustmentMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(serverObject.adjustmentMBF),
      significantFigures: this.adjustmentPounds.toString().length
    })
    this.correctedMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(serverObject.correctedMBF),
      significantFigures: this.correctedPounds.toString().length
    })
    this.expectedMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(serverObject.expectedMBF),
      significantFigures: this.expectedPounds.toString().length
    })
    this.harvestedMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(serverObject.harvestedMBF) + parseFloat(serverObject.correctedMBF),
      significantFigures: this.harvestedPounds.toString().length
    })
    this.remainingMBF = toPrecisionFloatTwoDecimalPlaces({
      value: Math.max(0, Math.round(
        parseFloat(serverObject.expectedMBF) -
        parseFloat(serverObject.harvestedMBF) -
        parseFloat(serverObject.correctedMBF) -
        parseFloat(serverObject.adjustmentMBF)
        , 2)),
      significantFigures: this.remainingPounds.toString().length
    })

    this.pricePerTon = serverObject.pricePerTon
    this.pricePerMBF = serverObject.pricePerMBF
    this.product = serverObject.product
    this.productId = serverObject.productId
    this.tractContentId = serverObject.tractContentId
    this.woodType = serverObject.woodType

    // Computed
    this.cruisedValue = parseFloat((this.pricePerTon * this.expectedTons).toFixed(2))
  }

  clone () {
    return new TractContent(this.serverObject)
  }
}

export class TractFilter {
  constructor ({
    useLocalStorageKey = false,
    includeInitiated = false,
    includeCruised = false,
    includeExecuted = false,
    includeActive = true,
    includeClosed = false,
    includeArchived = false,
    selectedForesters = [],
    selectedTractTypes = [],
    selectedTeams = [],
    nameContains = undefined
  } = {}) {
    if (useLocalStorageKey) {
      const statusFromLocalStorage = localStorage.getItem(LocalStorageKeys.TRACT_STATUS_FILTER) ? JSON.parse('[' + localStorage.getItem(LocalStorageKeys.TRACT_STATUS_FILTER) + ']') : [3]
      this.includeInitiated = statusFromLocalStorage.includes(0)
      this.includeCruised = statusFromLocalStorage.includes(1)
      this.includeExecuted = statusFromLocalStorage.includes(2)
      this.includeActive = statusFromLocalStorage.includes(3)
      this.includeClosed = statusFromLocalStorage.includes(4)
      this.includeArchived = statusFromLocalStorage.includes(5)
    } else {
      this.includeInitiated = includeInitiated
      this.includeCruised = includeCruised
      this.includeExecuted = includeExecuted
      this.includeActive = includeActive
      this.includeClosed = includeClosed
      this.includeArchived = includeArchived
    }
    this.selectedForesters = selectedForesters
    this.selectedTractTypes = selectedTractTypes
    this.selectedTeams = selectedTeams
    this.nameContains = nameContains
  }

  getQueryString () {
    const qq = new URLSearchParams()

    if (this.includeInitiated) qq.append('includeInitiated', true)
    if (this.includeCruised) qq.append('includeCruised', true)
    if (this.includeExecuted) qq.append('includeExecuted', true)
    if (this.includeActive) qq.append('includeActive', true)
    if (this.includeClosed) qq.append('includeClosed', true)
    if (this.includeArchived) qq.append('includeArchived', true)
    if (this.nameContains) qq.append('nameContains', this.nameContains)

    return qq.toString()
  }
}

export class TractAnalysis {
  constructor (analysis) {
    this.totalCost = analysis.totalCost
    this.totalGrossProfit = analysis.totalGrossProfit
    this.purchaseCost = analysis.purchaseCost
    this.balance = analysis.balance
    this.totalProductionCost = analysis.totalProductionCost
    this.totalRevenue = analysis.totalRevenue
    this.totalStumpageCost = analysis.totalStumpageCost
    this.totalTractPayments = analysis.totalTractPayments
    this.tractProfitLoss = analysis.tractProfitLoss
    this.tractHarvestAnalysis = analysis.tractHarvestAnalysis.map(th => new TractHarvest(th, this.totalTractPayments))
  }
}

export class TractHarvest {
  constructor (tractHarvest, totalTractPayments) {
    const lbsToTons = (lbs) => parseFloat((lbs / 2000).toFixed(3))

    this.cruisedValue = tractHarvest.cruisedValue
    this.purchaseAllocation = tractHarvest.purchaseAllocation
    this.percentageOfTotalHarvest = tractHarvest.percentageOfTotalHarvest
    this.expectedLBS = tractHarvest.expectedLBS
    this.expectedTons = lbsToTons(tractHarvest.expectedLBS)
    this.expectedMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(tractHarvest.expectedMBF),
      significantFigures: this.expectedLBS.toString().length
    })
    this.harvestedLBS = tractHarvest.harvestedLBS
    this.harvestedTons = lbsToTons(tractHarvest.harvestedLBS)
    this.harvestedMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(tractHarvest.harvestedMBF),
      significantFigures: this.harvestedLBS.toString().length
    })
    this.ticketWeightLBS = tractHarvest.ticketWeightLBS
    this.ticketWeightTons = lbsToTons(tractHarvest.ticketWeightLBS)
    this.ticketVolumeMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(tractHarvest.ticketVolumeMBF),
      significantFigures: this.ticketWeightLBS.toString().length
    })
    this.remainingLBS = tractHarvest.remainingLBS
    this.remainingTons = lbsToTons(tractHarvest.remainingLBS)
    this.remainingMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(tractHarvest.remainingMBF),
      significantFigures: this.remainingLBS.toString().length
    })
    this.loads = tractHarvest.loads
    this.pricePerTon = tractHarvest.pricePerTon
    this.pricePerMBF = tractHarvest.pricePerMBF
    this.product = tractHarvest.product
    this.productId = tractHarvest.productId
    this.woodType = tractHarvest.woodType
    this.cutoutValue = tractHarvest.harvestedCruiseValue
    this.cutoutPercentage = tractHarvest.cutoutPercentage
    this.overcutLBS = tractHarvest.overcutLBS
    this.overcutTons = lbsToTons(tractHarvest.overcutLBS)
    this.overcutMBF = toPrecisionFloatTwoDecimalPlaces({
      value: parseFloat(tractHarvest.overcutMBF),
      significantFigures: this.overcutLBS.toString().length
    })
    this.overcutPercentage = tractHarvest.overcutPercentage
    this.overUnderCruiseValue = tractHarvest.overUnderCruiseValue
    this.harvestedCruiseValue = tractHarvest.harvestedCruiseValue
    this.remainingCruiseValue = tractHarvest.remainingCruiseValue
    this.overUnderPurchaseValue = tractHarvest.overUnderPurchaseValue
    this.remainingPurchaseValue = tractHarvest.remainingPurchaseValue
    this.revenue = tractHarvest.revenue
    this.grossProfit = tractHarvest.grossProfit
    this.grossProfitPerTon = tractHarvest.grossProfitPerTon
    this.grossProfitPerMBF = tractHarvest.grossProfitPerMBF

    this.actualCostPerTon = tractHarvest.actualCostPerTon
    this.productionCostPerTon = tractHarvest.productionCostPerTon
    this.stumpageCostPerTon = tractHarvest.stumpageCostPerTon

    this.totalProductionCost = tractHarvest.totalProductionCost
    this.totalCost = tractHarvest.totalCost
    this.totalStumpageCost = tractHarvest.totalStumpageCost

    this.totalTractPayments = totalTractPayments
  }
}

function toPrecisionFloatTwoDecimalPlaces ({ value, significantFigures }) { // Matches significant figures up to 2 decimal places
  return parseFloat(parseFloat(value.toPrecision(significantFigures)).toFixed(2))
}
