import { LocalStorageKeys } from '@/utils/constants'
import { LocalStorageActor } from '@/utils/LocalStorageActor'

export default {
  standingTimberFilter: new LocalStorageActor({
    key: LocalStorageKeys.STANDING_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [], team: [], myTracts: false }),

    read: ({ status, tractType, tractForester, team = [], myTracts = false } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester,
      team,
      myTracts
    }),

    write: ({ status, tractType, tractForester, team, myTracts }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId),
      team: team.map(t => t.teamId),
      myTracts
    })
  }),

  committedTimberFilter: new LocalStorageActor({
    key: LocalStorageKeys.COMMITTED_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [], team: [], myTracts: false }),

    read: ({ status, tractType, tractForester, team = [], myTracts = false } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester,
      team,
      myTracts
    }),

    write: ({ status, tractType, tractForester, team, myTracts }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId),
      team: team.map(t => t.teamId),
      myTracts
    })
  })
}
