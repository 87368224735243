import ApiServer from '@/utils/api/ApiServer.js'
import { Tract, StandingTimber, CommittedTimber, TractFilter, TractAnalysis } from '@/model/Tract.js'
import { azureMapsApiKey } from '../../../../env-var.js'
import { get } from 'axios'
import moment from 'moment'
import persist from './persist.js'
import { localeCompareAlphanumeric } from '@/utils/base'
import store from '@/store'

export default {
  async fetchTracts (context, filter = new TractFilter()) {
    try {
      context.commit('setLoading', true)
      const path = `tracts?${filter.getQueryString()}`
      const response = await ApiServer.get(path)
      let tracts = response.data.map(tractResponse => new Tract(tractResponse))
      if (filter.selectedForesters.length > 0) {
        const selectedIds = filter.selectedForesters.map(f => f?.applicationUserId)
        tracts = tracts.filter(tract => selectedIds.includes(tract.foresterUser?.applicationUserId))
      }
      if (filter.selectedTractTypes.length > 0) {
        const selectedIds = filter.selectedTractTypes.map(tt => tt?.tractTypeId)
        tracts = tracts.filter(tract => selectedIds.includes(tract.type?.tractTypeId))
      }
      if (filter.selectedTeams.length > 0) {
        const selectedIds = filter.selectedTeams.map(t => t?.teamId)
        tracts = tracts.filter(tract => selectedIds.includes(tract.teamId))
      }
      context.commit('setTracts', tracts)
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchTractsOnce (_, filter = new TractFilter()) {
    const path = `tracts?${filter.getQueryString()}`
    const { data } = await ApiServer.get(path)
    return data.map(t => new Tract(t))
  },
  async fetchTract (_, tractId) {
    const path = `tracts/${tractId}`
    const response = await ApiServer.get(path)
    if (response.data.users) response.data.users.sort((a, b) => localeCompareAlphanumeric(a.name, b.name))
    return response.data
  },
  async deleteTract (context, tractId) {
    const response = await ApiServer.delete(`tracts/${tractId}`)
    context.commit('removeTract', tractId)
    return response.data
  },
  async createTract (_, tract) {
    const response = await ApiServer.post('tracts', tract, {
      headers: {
        tractTypeId: tract.tractTypeId
      }
    })
    return response.data
  },
  async fetchTractTimberVolume (context, payload) {
    try {
      context.commit('setLoading', true)
      const path = `tracts/${payload.tractId}/timbervolume?lowerBound=${payload.startDate}&upperBound=${payload.endDate}`
      const response = await ApiServer.get(path)
      context.commit('setTractTimberVolume', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async getLoggersForTract (_, tractId) {
    const path = `tracts/${tractId}/loggers`
    const response = await ApiServer.get(path)
    return response.data
  },
  async getTractWithId (_, tractId) {
    const path = `tracts/${tractId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async updateTract (_, tract) {
    const path = `tracts/${tract.tractId}`
    const headers = {
      forceDownChanges: tract.shouldApplyDates ?? false
    }

    const response = await ApiServer.put(path, tract, { headers: headers })
    return response.data
  },

  async fetchTractPayables (_, tractId) {
    const path = `tracts/${tractId}/tractPayables`
    const response = await ApiServer.get(path)
    return response.data
  },

  async createTractPayment (_, requestObj) {
    const { tractId, tractPayment } = requestObj
    const path = `tracts/${tractId}/tractpayables`
    const response = await ApiServer.post(path, tractPayment)
    return response.data
  },

  async updateTractPayment (_, requestObj) {
    const { tractId, tractPayment } = requestObj
    tractPayment.tractId = tractId
    const path = `tracts/${tractId}/tractpayables/${tractPayment.tractPayableId}`
    const response = await ApiServer.put(path, tractPayment)
    return response.data
  },

  async deleteTractPayable (_, requestObj) {
    const { tractId, tractPayableId } = requestObj
    const path = `tracts/${tractId}/tractpayables/${tractPayableId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async getTractDeliveredCosts (_) {
    const path = 'dashboard/tractdeliveredcosts'
    const response = await ApiServer.get(path)
    return response.data
  },

  async getTractProductCosts (_, params) {
    let path = 'tracts/tractCosts?'
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && value !== null) {
        path += `${key}=${value}&`
      }
    }
    const response = await ApiServer.get(path)
    return response.data
  },

  async getDetailedTractProductCosts (_, { tractId, businessEntityId, params }) {
    let path = `tracts/${tractId}/tractCosts`
    if (businessEntityId !== null) {
      path = `${path}?businessEntityId=${businessEntityId}&`
    } else {
      path = `${path}?`
    }
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        path += `${key}=${value}&`
      }
    }
    const response = await ApiServer.get(path)
    return response.data
  },

  async getTractHarvest (_, { tractId, asOfDate }) {
    const path = ApiServer.urlFor(`tracts/${tractId}/tractHarvest`, {
      params: {
        asAt: asOfDate ? moment.utc(asOfDate).add(1, 'days').format() : undefined
      }
    })
    const { data: tractHarvestData } = await ApiServer.get(path)
    return new TractAnalysis(tractHarvestData)
  },

  async getTractMetrics (_, { tractId, startDate, endDate }) {
    const path = ApiServer.urlFor(`tractMetrics/${tractId}`, {
      params: {
        sinceTime: startDate,
        untilTime: endDate,
        includeInternal: true,
        includeExternal: true,
        useLoadCreatedAt: true
      }
    })
    const { data: tractMetricsData } = await ApiServer.get(path)
    return tractMetricsData
  },

  async fetchTractTickets (context, requestObject) {
    const { tractId, sinceTime, untilTime } = requestObject
    let path = `v2/tracts/${tractId}/tickets`
    if (sinceTime || untilTime) path += '?'
    if (sinceTime) path += `sinceTime=${sinceTime}`
    if (sinceTime && untilTime) path += '&'
    if (untilTime) path += `untilTime=${untilTime}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchStandingTimber (context, { asOfDate, businessEntityId, statuses, tractTypes, tractForesters, teams, myTracts }) {
    if (!(statuses?.length > 0) && !(tractTypes?.length > 0)) return []
    context.commit('setLoading', true)
    try {
      if (asOfDate) {
        asOfDate = moment.utc(asOfDate).add(1, 'days').format()
      }
      const statusSet = new Set(statuses)
      const url = ApiServer.urlFor('dashboard/standingTimber', {
        params: {
          asAt: asOfDate ?? undefined,
          businessEntityId: businessEntityId ?? undefined,
          includeExecuted: statusSet.has(2),
          includeActive: statusSet.has(3),
          includeClosed: statusSet.has(4),
          tractTypeIds: tractTypes.length > 0 ? tractTypes.map(tt => tt.tractTypeId).join(',') : undefined,
          tractForesterIds: tractForesters.length > 0 ? tractForesters.map(tf => tf.applicationUserId).join(',') : undefined,
          teamIds: teams.length > 0 ? teams.map(t => t.teamId).join(',') : undefined,
          tractsForUserWithId: myTracts ? store.getters['user/userInfo'].applicationUserId : undefined
        }
      })
      const { data: standingTimber } = await ApiServer.get(url)
      return standingTimber.map(t => new StandingTimber(t))
    } finally {
      context.commit('setLoading', false)
    }
  },

  async fetchCommittedTimber (context, { statuses = [], tractTypes = [], tractForesters = [], teams = [], myTracts = false } = {}) {
    if (!(statuses?.length > 0) && !(tractTypes?.length > 0)) return []
    context.commit('setLoading', true)
    try {
      const statusSet = new Set(statuses)
      const url = ApiServer.urlFor('dashboard/committedTimber', {
        params: {
          includeInitiated: statusSet.has(0),
          includeCruised: statusSet.has(1),
          includeActive: statusSet.has(3),
          tractTypeIds: tractTypes.length > 0 ? tractTypes.map(tt => tt.tractTypeId).join(',') : undefined,
          tractForesterIds: tractForesters.length > 0 ? tractForesters.map(tf => tf.applicationUserId).join(',') : undefined,
          teamIds: teams.length > 0 ? teams.map(t => t.teamId).join(',') : undefined,
          tractsForUserWithId: myTracts ? store.getters['user/userInfo'].applicationUserId : undefined
        }
      })
      const { data: committedTimber } = await ApiServer.get(url)
      return committedTimber.map(t => new CommittedTimber(t))
    } finally {
      context.commit('setLoading', false)
    }
  },

  async getLocationWithCoordinate (_, coordinate) {
    try {
      const result = await searchCoordinatesWithAzure(coordinate)
      return result
    } catch (error) {
      console.log(error)
    }

    // If for some reason Azure Map Service is down, hopefully the government isn't.
    try {
      const result = await searchCoordinatesWithFCC(coordinate)
      return result
    } catch (error) {
      console.log(error)
    }

    throw new Error('Error getting results from API.')
  },

  async getLocationWithCompanyAddress ({ rootState }, _address = undefined) {
    const address = _address ?? rootState.user.companyInfo?.address

    if (!address) {
      throw new Error('Could not retrive address from company info.')
    }

    const result = await searchAddressWithAzure(address)
    return result
  },

  async getWeatherForTract (_, coordinatesDatesUnits) {
    try {
      const result = await searchWeatherWithAzure(coordinatesDatesUnits)
      return result
    } catch (error) {
      console.log(error)
      throw new Error('Error getting weather results from API.')
    }
  },

  async fetchTractLandowners (_, tractId) {
    const path = `tracts/${tractId}/tractlandowners`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchContractsForTract (_, tractId) {
    const path = `v2/tracts/${tractId}/contracts`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchActiveTractsForPeriod (_, { startDate, endDate }) {
    const url = ApiServer.urlFor('activeTractsInPeriod', {
      params: { sinceTime: startDate, untilTime: endDate }
    })
    const response = await ApiServer.get(url)
    return response.data
  },

  setStandingTimberFilter (context, filter) {
    context.commit('setStandingTimberFilter', filter)
  },

  readStandingTimberFilter (context) {
    const filter = persist.standingTimberFilter.read(context)
    if (filter !== undefined) context.commit('setStandingTimberFilter', filter)
  },

  setCommittedTimberFilter (context, filter) {
    context.commit('setCommittedTimberFilter', filter)
  },

  readCommittedTimberFilter (context) {
    const filter = persist.committedTimberFilter.read(context)
    if (filter !== undefined) context.commit('setCommittedTimberFilter', filter)
  }
}

const searchAddressWithAzure = async (companyAddress) => {
  const query = `${companyAddress.addressLine1},${companyAddress.city},${companyAddress.state},${companyAddress.postalCode}`
  const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
  const format = 'JSON'
  const apiVersion = '1.0'
  const requestURL = `https://atlas.microsoft.com/search/address/${format}?api-version=${apiVersion}&subscription-key=${apiKey}&query=${query}`
  const encodedURL = encodeURI(requestURL)

  const { data } = await get(encodedURL)
  const results = data.results
  if (results.length > 0) {
    const position = results[0].position
    const address = results[0].address
    return {
      latitude: position.lat,
      longitude: position.lon,
      countrySubdivision: address.countrySubdivision,
      countrySecondarySubdivision: address.countrySecondarySubdivision
    }
  }

  throw new Error('No results with Azure.')
}

const searchCoordinatesWithAzure = async ({ latitude, longitude }) => {
  const query = `${latitude},${longitude}`
  const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
  const format = 'JSON'
  const apiVersion = '1.0'
  const requestURL = `https://atlas.microsoft.com/search/address/reverse/${format}?api-version=${apiVersion}&subscription-key=${apiKey}&query=${query}`
  const encodedURL = encodeURI(requestURL)

  const { data } = await get(encodedURL)
  const addresses = data.addresses
  if (addresses.length > 0) {
    const address = addresses[0].address
    return {
      countrySubdivision: address.countrySubdivision,
      countrySecondarySubdivision: address.countrySecondarySubdivision
    }
  }

  throw new Error('No results with Azure.')
}

const searchWeatherWithAzure = async ({ latitude, longitude, startDate, endDate, units }) => {
  const query = `${latitude},${longitude}`
  const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
  const format = 'JSON'
  const apiVersion = '1.0'
  const requestURL = `https://atlas.microsoft.com/weather/historical/actuals/daily/${format}?api-version=${apiVersion}&subscription-key=${apiKey}&unit=${units}&query=${query}&startDate=${startDate}&endDate=${endDate}`
  const encodedURL = encodeURI(requestURL)

  const { data } = await get(encodedURL)
  if (data) {
    return data
  }

  throw new Error('Error getting weather data from Azure.')
}

const searchCoordinatesWithFCC = async ({ latitude, longitude }) => {
  const { data } = await get(`https://geo.fcc.gov/api/census/area?lat=${latitude}&lon=${longitude}&format=json`)
  if (data.length > 0) {
    const address = data[0]
    return {
      countrySubdivision: address.county_name,
      countrySecondarySubdivision: address.county_code
    }
  }

  throw new Error('No results with FCC.')
}
