<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-text-field v-if="!loading"
            v-model="tract.name"
            data-testid="tract-name"
            ref="firstField"
            :label="$t('tractName')"
            :rules="[rules.required]"
            color="black"
            counter
            maxlength="40"
            persistent-hint
            :hint="$t('required')"
          ></v-text-field>
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field v-if="!loading"
            v-model="tract.code"
            data-testid="tract-code"
            :label="$t('tractCode')"
            color="black"
            counter
            maxlength="10"
          ></v-text-field>
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <TractTypeSelector v-if="!loading"
            data-testid="tract-type"
            :tractTypeId="tract.type.tractTypeId"
            @tract-type="tractTypeChosen"
            :hint="$t('required')"
            :persistentHint="true"
            :propFetchTractTypes="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <AccountAutocomplete v-if="!loading"
          id="tract-logger"
          data-testid="tract-logger"
          :accountId="tract.loggerAccountId"
          :title="$t('defaultLogger')"
          clearable
          showCertified
          userSetting="defaultLoggerAutocomplete"
          @account-chosen="defaultLoggerChosen"
          :fetchTagsAndAccounts="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <AccountAutocomplete v-if="!loading"
          id="hauler"
          data-testid="tract-hauler"
          :accountId="tract.haulerAccountId"
          :title="$t('hauler')"
          clearable
          showCertified
          userSetting="haulerAccountAutocomplete"
          @account-chosen="haulerChosen"
          :fetchTagsAndAccounts="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <AccountAutocomplete v-if="!loading"
          id="supplier"
          data-testid="tract-supplier"
          :accountId="tract.supplierAccountId"
          :title="$t('supplier')"
          clearable
          showCertified
          userSetting="supplierAccountAutocomplete"
          @account-chosen="supplierChosen"
          :fetchTagsAndAccounts="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <AccountAutocomplete v-if="!loading"
          id="consulting-forester"
          data-testid="tract-consulting-forester"
          :accountId="tract.consultingForesterAccountId"
          :title="$t('consultingForester')"
          clearable
          showCertified
          userSetting="consultingForesterAccountAutocomplete"
          @account-chosen="consultingForesterChosen"
          :fetchTagsAndAccounts="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <AccountAutocomplete v-if="!loading"
          id="contract-account"
          data-testid="tract-contract-account"
          :accountId="tract.contractAccountId"
          :title="$t('contractAccount')"
          clearable
          showCertified
          userSetting="tractContractAccountAutocomplete"
          @account-chosen="contractAccountChosen"
          :fetchTagsAndAccounts="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <SettingAutocomplete v-if="!loading"
          :settingName="tract.contractSetting"
          @setting-chosen="contractSettingChosen"
          hideRefresh
          data-testid="tract-contract-setting"
          :propFetchSettings="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" :md="!showTeamSelection ? 6 : 12" :lg="!showTeamSelection ? 4 : 12">
          <v-row>
            <v-col cols="12" md="6" lg="3" v-if="showTeamSelection"> <!-- Team -->
              <TeamAutocomplete
              v-if="!loading"
              :propTeamId="propTract.teamId"
              @team-chosen="teamChosen"/>
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
            <v-col cols="12" lg="6" order-md="last" order-lg="0" v-if="showTeamSelection" id="tract-users-autocomplete"> <!-- Tract Users -->
              <UserAutocomplete v-if="!loading"
              multiple
              chips
              smallChips
              deletableChips
              dataTestid="tract-users-autocomplete"
              :label="$t('users')"
              :fetchUsers="false"
              :propTeam="team"
              :propUserIds="propTract.userIds"
              :disabled="!team || !tract.teamId"
              :rules="!!team ? [rules.requiredSelect] : []"
              @user-chosen="usersChosen"/>
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
            <v-col cols="12" :md="showTeamSelection ? 6 : ''" :lg="showTeamSelection ? 3 : ''"> <!-- Forester -->
              <UserAutocomplete v-if="!loading"
              :label="$t('forester')"
              :propUserId="tract.foresterUserId"
              data-testid="tract-forester"
              @user-chosen="foresterChosen"
              :fetchUsers="false"
              :allUserIds="!!team ? tract.userIds ?? [] : undefined"/> <!-- All users available unless a team is selected, in which case the chosen users are available -->
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isStumpageTract" cols="12" md="6">
          <EntitySelector v-if="!loading"
            :entities="entities"
            :initialEntity.sync="tract.businessEntity"
            @entity-selected="entitySelected"
            :hint="$t('required')"
            :persistentHint="true"
            :label="$t('purchaser')"
          />
          <v-skeleton-loader v-else type="image" max-height="70" max-width="300"/>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <MoneyTextField v-if="!loading"
          data-testid="tract-cost"
          :initialValue="tract.cost"
          :label="$t('cost')"
          @val-changed="tractCostEntered"
          :extraRules="['validTractCost']"/>
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <DatePicker
          v-if="isStumpageTract && !loading"
          :dateLabel="$t('purchaseDate')"
          :startDate="tract.purchaseDate"
          dataTestId="tract-purchase-date"
          clearable
          @date-picked="purchaseDatePicked"
          @valid-date="(val) => {
            isValidPurchaseDate = val
          }"
          ></DatePicker>
          <v-skeleton-loader v-else-if="isStumpageTract" type="image" max-height="70"/>
        </v-col>
      </v-row>
      <TractLandowners
      :propLandowners="landowners"
      :loading="loading"
      @ownership-changed="landowners = $event"/>
      <v-divider class="my-6"></v-divider>
      <v-row dense>
        <v-col cols="12" md="6">
          <DatePicker
          @date-picked="startDatePicked"
          clearable
          dataTestId="tract-harvest-start-date"
          :startDate="tract.harvestStartDate"
          v-if="renderDates && !loading"
          @valid-date="(val) => {
            isValidStartDate = val
          }"
          :dateLabel="$t('harvestStart')"
          ></DatePicker>
          <v-skeleton-loader v-else-if="renderDates" type="image" max-height="70"/>
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker
          @date-picked="endDatePicked"
          clearable
          dataTestId="tract-harvest-end-date"
          :startDate="tract.harvestEndDate"
          v-if="renderDates && !loading"
          @valid-date="(val) => {
            isValidEndDate = val
          }"
          :dateLabel="$t('harvestEnd')"
          ></DatePicker>
          <v-skeleton-loader v-else-if="renderDates" type="image" max-height="70"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-checkbox v-if="!loading"
            data-testid="contract-requires-ext-ticket-1"
            v-model="tract.requiresExt1"
            color="secondary"
            :label="$t('requiresExt1')"
          />
          <v-skeleton-loader v-else type="image" height="70" width="230"/>
        </v-col>
        <v-col cols="auto">
          <v-checkbox v-if="!loading"
            data-testid="contract-requires-ext-ticket-2"
            v-model="tract.requiresExt2"
            color="secondary"
            :label="$t('requiresExt2')"
          />
          <v-skeleton-loader v-else type="image" height="70" width="230"/>
        </v-col>
        <v-col cols="auto" v-if="propTract.tractId">
          <v-checkbox
          v-if="didHarvestDatesChange"
          v-model="applyDateChangesToContracts"
          color="secondary"
          label="Apply harvest dates to contracts"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <LoggerAutocomplete v-if="!loading"
          data-testid="tract-loggers"
          :loggers="tract.loggers"
          userSetting="accountLoggerAutocomplete"
          @loggers-mutated="loggersChosen"
          :fetchTagsAndAccounts="false">
          </LoggerAutocomplete>
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <Certifications v-if="!loading"
            label
            :category="0"
            data-testid="tract-certifications"
            :propCertifications="tract.certificationIds"
            @certifications="certificationChosen"
            useIdOnly
            :propFetchCertifications="false"
          />
          <v-skeleton-loader v-else type="image" max-height="70"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="6">
          <span class="subtitle-1" v-if="!loading">{{$t('quality')}}</span>
          <v-skeleton-loader type="text" height="19" max-width="50" v-else/>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field v-if="!loading"
              :label="$t('rpi')"
              v-model="tract.rpi"
              :rules="[rules.numberOrBlank, rules.twoDecimalPlacesOrFewer, rules.validRPI]"
              maxLength="5"
              />
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-if="!loading"
              :label="$t('dbh')"
              v-model="tract.dbh"
              :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validDBH]"
              maxLength="3"
              />
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-if="!loading"
              :label="$t('age')"
              v-model="tract.age"
              :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validAge]"
              maxLength="3"
              />
              <v-skeleton-loader v-else type="image" max-height="70"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'GeneralInformation',

  props: {
    propTract: Object,
    propLandowners: Array
  },

  components: {
    Certifications: () => import('@/components/settings-components/certification/Certifications.vue'),
    TractTypeSelector: () => import('@/components/tract/TractTypeSelector.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    SettingAutocomplete: () => import('@/components/autocomplete/ContractSettingAutocomplete.vue'),
    UserAutocomplete: () => import('@/components/autocomplete/UserAutocomplete.vue'),
    LoggerAutocomplete: () => import('@/components/autocomplete/LoggerAutocomplete.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    TractLandowners: () => import('@/components/tract/tract-form/TractLandowners.vue'),
    TeamAutocomplete: () => import('@/components/autocomplete/TeamAutocomplete.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    entities: [],
    isValidStartDate: true,
    renderDates: true,
    isValidEndDate: true,
    isValidPurchaseDate: true,
    applyDateChangesToContracts: false,
    originalHarvestStart: null,
    originalHarvestEnd: null,
    tract: {
      name: '',
      code: '',
      type: {
        tractTypeId: null
      },
      cost: 0,
      foresterUserId: null,
      businessEntity: null,
      loggerAccountId: null,
      haulerAccountId: null,
      supplierAccountId: null,
      contractAccountId: null,
      contractSettingId: null,
      contractSetting: null,
      loggers: [],
      loggerAccountIds: [],
      certificationIds: [],
      harvestStartDate: new Date().toISOString(),
      harvestEndDate: new Date().toISOString(),
      purchaseDate: new Date().toISOString(),
      requiresExt1: false,
      requiresExt2: false,
      consultingForesterAccountId: null,
      rpi: null,
      dbh: null,
      age: null,
      userIds: [],
      teamId: undefined
    },
    landowners: [],
    loading: true,
    team: undefined
  }),

  computed: {
    ...mapGetters('team', ['allTeams']),

    isStumpageTract () {
      return this.tract.type?.category === TractTypeCategory.Stumpage.value
    },

    didHarvestDatesChange () {
      if (this.tract.harvestStartDate === null || this.tract.harvestEndDate === null) return false
      const isStartDayEqual = moment(this.tract.harvestStartDate).isSame(moment(this.originalHarvestStart), 'day')
      const isEndDayEqual = moment(this.tract.harvestEndDate).isSame(moment(this.originalHarvestEnd), 'day')
      return !(isStartDayEqual && isEndDayEqual)
    },

    type () {
      return this.tract.type
    },

    teamUserIds () {
      return this.team?.users?.map(u => u.applicationUserId)
    },

    showTeamSelection () {
      return this.allTeams?.filter(t => t.isActive).length > 0 || this.propTract.teamId || this.propTract.users?.length > 0
    }
  },

  watch: {
    tract: {
      handler (val) {
        this.$emit('tract-changed', val)
      },
      deep: true
    },

    type (val, oldVal) {
      if (this.propTract.tractId || (this.tract.harvestStartDate || this.tract.harvestEndDate)) {
        return
      }

      const selectedDeliveredType = oldVal.tractTypeId === null && val.category === TractTypeCategory.Delivered.value
      const switchedFromStumpage = val.category === TractTypeCategory.Delivered.value && (!this.tract.harvestStartDate && !this.tract.harvestEndDate)
      if (selectedDeliveredType || switchedFromStumpage) {
        this.tract.harvestStartDate = moment().startOf('day').format()
        this.tract.harvestEndDate = moment().add(90, 'day').startOf('day').format()
        this.renderDates = false
        this.$nextTick(_ => {
          this.renderDates = true
        })
      }
    },

    applyDateChangesToContracts (shouldApplyDates) {
      this.$emit('apply-to-contracts-toggled', shouldApplyDates)
    },

    landowners: {
      handler (val) {
        this.$emit('ownership-changed', val)
      },
      deep: true
    },

    propLandowners: {
      handler () {
        this.landowners = this.propLandowners
      },
      deep: true
    },

    team (team) {
      this.tract.teamId = team?.teamId
    }
  },

  async created () {
    this.setInitialValues(this.propTract, this.propLandowners)
    const [entities] = await Promise.all([
      this.fetchAllBusinessEntities(),
      this.fetchTeams(),
      this.fetchAccounts(),
      this.fetchAccountTags(),
      this.getAllUsers(),
      this.fetchCertifications(),
      this.fetchSettings(),
      this.fetchTractTypes()
    ])
    if (this.tract.teamId) this.team = this.allTeams.find(t => t.teamId === this.tract.teamId)
    this.entities = entities
    this.loading = false
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapActions('user', ['fetchAllBusinessEntities', 'getAllUsers']),
    ...mapActions('certification', ['fetchCertifications']),
    ...mapActions('setting', ['fetchSettings']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('team', ['fetchTeams']),

    setInitialValues (propTract, propLandowners) {
      this.tract.name = propTract.name
      this.tract.code = propTract.code
      this.tract.type = propTract.type
      this.tract.foresterUserId = propTract.foresterUser?.applicationUserId
      this.tract.harvestStartDate = propTract.harvestStartDate
      this.tract.harvestEndDate = propTract.harvestEndDate
      this.tract.purchaseDate = propTract.purchaseDate
      this.tract.cost = propTract.cost
      this.tract.businessEntity = {
        name: propTract.businessEntity,
        businessEntityId: propTract.businessEntityId
      }
      this.tract.loggerAccountId = propTract.loggerAccountId
      this.tract.haulerAccountId = propTract.haulerAccountId
      this.tract.supplierAccountId = propTract.supplierAccountId
      this.tract.contractAccountId = propTract.contractAccountId
      this.tract.contractSettingId = propTract.contractSettingId
      this.tract.contractSetting = propTract.contractSetting
      this.tract.requiresExt1 = propTract.requiresExt1 ?? false
      this.tract.requiresExt2 = propTract.requiresExt2 ?? false
      this.tract.certificationIds = propTract.tractCertifications?.map(c => c.certificationId)
      this.tract.loggers = propTract.loggers ?? []
      this.originalHarvestStart = this.propTract.harvestStartDate
      this.originalHarvestEnd = this.propTract.harvestEndDate
      this.tract.consultingForesterAccountId = propTract.consultingForesterAccountId
      this.tract.rpi = propTract.rpi || undefined
      this.tract.dbh = propTract.dbh || undefined
      this.tract.age = propTract.age || undefined
      this.landowners = propLandowners
      this.tract.userIds = propTract.userIds
      this.tract.teamId = propTract.teamId
    },

    tractCostEntered (cost) {
      this.tract.cost = cost
    },

    tractTypeChosen (tractType) {
      if (tractType.category === TractTypeCategory.Delivered.value) {
        this.tract.businessEntity = null
      }
      this.tract.type = tractType
    },

    certificationChosen (certifications) {
      this.tract.certificationIds = certifications
    },

    entitySelected (entity) {
      this.tract.businessEntity = entity
    },

    loggersChosen (loggers) {
      this.tract.loggerAccountIds = loggers.map(l => l.accountId)
    },

    defaultLoggerChosen (defaultLogger) {
      this.tract.loggerAccountId = defaultLogger?.accountId
    },

    haulerChosen (hauler) {
      this.tract.haulerAccountId = hauler?.accountId
    },

    supplierChosen (supplier) {
      this.tract.supplierAccountId = supplier?.accountId
    },

    consultingForesterChosen (consultingForester) {
      this.tract.consultingForesterAccountId = consultingForester?.accountId
    },

    contractAccountChosen (contractAccount) {
      this.tract.contractAccountId = contractAccount?.accountId
    },

    contractSettingChosen (setting) {
      this.tract.contractSettingId = setting?.settingId
    },

    foresterChosen (forester) {
      this.tract.foresterUserId = forester?.applicationUserId ?? null
    },

    startDatePicked (startDate) {
      this.tract.harvestStartDate = startDate
    },

    endDatePicked (endDate) {
      this.tract.harvestEndDate = endDate
    },

    purchaseDatePicked (purchaseDate) {
      this.tract.purchaseDate = purchaseDate
    },

    teamChosen (team) {
      this.team = team
      if (team?.users) this.usersChosen(team.users)
    },

    usersChosen (users) {
      if (users) { this.tract.userIds = users.map(u => u.applicationUserId) } else this.tract.userIds = []
    },

    userIdsChosen (userIds) {
      this.tract.userIds = userIds
    }

  }
}
</script>
