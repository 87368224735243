<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="auto">
        <v-card flat :loading="tractsLoading" :style="totalsStyle">
          <v-card-title>
            {{$t('totals')}}
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <v-row v-for="(ctt, index) in committedTimberTotals" :key="`ctt-${ctt.label}-${index}`" dense>
              <v-col>{{ctt.label}}</v-col>
              <v-col class="text-right">{{ctt.value}}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="" class="fill-width" style="min-width: 0;">
        <DataTable
        :items="committedTimber"
        :customCells="customCells"
        :headers="headers"
        :loading="tractsLoading"
        >
          <template #custom-controls>
            <v-col cols="auto">
              <Icon
              @icon-clicked="myTractsClicked"
              :small="false"
              icon="mdi-account-circle"
              margin="mx-2"
              :tooltipText="committedTimberFilter.myTracts ? $t('allTracts') : $t('myTracts')"
              :elementId="'my-tracts-committed-timber'"/>
            </v-col>
            <v-col cols="auto">
              <CommittedTimberFilter
              @input="applyFilters"
              />
            </v-col>
            <v-col cols="auto">
              <ColumnAdjuster
              :propColumnSet.sync="columnSet"
              @new-columns-selected="newColumnsSelected"
              />
            </v-col>
            <v-col cols="auto">
              <Icon
              icon="mdi-file-delimited-outline"
              dataTestId="timber-download-button"
              :tooltipText="$t('downloadCSV')"
              @icon-clicked="downloadCSV"
              :small="false"/>
            </v-col>
            <v-col cols="auto">
              <Icon
              icon="mdi-refresh"
              dataTestId="refresh-icon-committed-timber"
              :tooltipText="$t('refresh')"
              @icon-clicked="refreshTracts"
              :small="false"/>
            </v-col>
          </template>
          <template #filters>
            <v-chip v-for="fk in activeFilters" :key="fk.key" color="black" class="white--text mr-1 mb-1" close @click:close="removeFilterField(fk.key)">
              <span style="font-weight: bold;">{{$t(fk.label)}}</span>
              <span v-if="!!fk.text">{{`: ${fk.text}`}}</span>
            </v-chip>
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-information-outline"
            :dataTestId="`committed-timber-${item.name}`"
            :small="false"
            :tooltipText="$t('viewDetailedBreakdown')"
            @icon-clicked="openDetailDialog(item)"/>
          </template>
          <template #team="{item}">
            <v-row no-gutters>
              <v-col cols="auto">
                <span>{{ item.teamName ?? $t('notAvailable') }}</span>
              </v-col>
              <v-col cols="auto">
                <Icon
                v-if="!!item.teamName"
                iconColor="black"
                tooltipColor="black"
                icon="mdi-dots-horizontal"
                margin="ml-1"
                :tooltipText="getTeamTooltip(item.tractUserNames)"
                />
              </v-col>
            </v-row>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <TractDetail
      v-if="focusedTractId"
      :tractId="focusedTractId"
      @edit-tract="editTract"
      :tabToOpen="1"/>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import RouterJump from '@/model/RouterJump.js'
import tractHeaders from '@/headers/Tract.js'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { TractStatus } from '../../../utils/Enumerations'
import { generateCsvString } from '../../../utils/CSVUtility'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'CommittedTimber',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    CommittedTimberFilter: () => import('./CommittedTimberFilter.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('committed-timber'),
    applyButtonDisabled: true,
    focusedTractId: null,
    committedTimber: [],
    filters: {
      status: [],
      tractForester: [],
      tractType: [],
      team: [],
      myTracts: false
    },
    totals: {
      expected: 0,
      harvested: 0,
      overcut: 0,
      cruisedValue: 0,
      tractCost: 0,
      stumpageValue: 0,
      cutoutValue: 0
    },
    columnSet: null
  }),

  computed: {
    ...mapGetters('tract', ['tractsLoading', 'committedTimberFilter']),
    ...mapGetters('tract-type', ['allTractTypes']),
    headers () {
      return this.columnSet?.getColumns() ?? []
    },

    customCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'team',
          value: 'teamName'
        }
      ]
    },

    activeFilters () {
      const keys = Object.keys(this.committedTimberFilter)
      return keys.map(key => ({
        key,
        label: key === 'tractForester' ? 'forester' : key,
        filter: this.committedTimberFilter[key],
        text: this.getFilterChipText(key, this.committedTimberFilter[key])
      }))
        .filter(kf => {
          switch (kf.label) {
            case 'myTracts':
              return kf.filter === true
            case 'forester':
            case 'tractType':
            case 'team':
            case 'status':
            default:
              return kf.filter.length > 0
          }
        })
    },

    committedTimberTotals () {
      const totals = (this.committedTimber ?? []).reduce((total, tract) => {
        total.expectedTons += tract.expectedTons
        total.harvestedTons += tract.harvestedTons
        total.remainingTons += tract.remainingTons
        total.cruisedValue += tract.cruisedValue
        return total
      }, {
        expectedTons: 0,
        harvestedTons: 0,
        remainingTons: 0,
        cruisedValue: 0
      })

      return [
        { label: this.$t('expectedTons'), value: numberWithCommas(totals.expectedTons, 3) },
        { label: this.$t('harvestedTons'), value: numberWithCommas(totals.harvestedTons, 3) },
        { label: this.$t('remainingTons'), value: numberWithCommas(totals.remainingTons, 3) },
        { label: this.$t('cruisedValue'), value: formatMoney(totals.cruisedValue) }
      ]
    },

    totalsStyle () {
      return {
        minWidth: '240px',
        maxWidth: '320px',
        width: this.$vuetify.breakpoint.mdAndDown ? '100vw' : '15vw'
      }
    }
  },

  watch: {
    committedTimberFilter: {
      handler () {
        this.refreshTracts()
      },
      deep: true
    }
  },

  async created () {
    this.initializeHeaders()
    await this.refreshTracts()
  },

  methods: {
    ...mapActions('tract', ['fetchCommittedTimber', 'setCommittedTimberFilter']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),
    formatMoney,
    numberWithCommas,
    generateCsvString,

    initializeHeaders () {
      this.columnSet = tractHeaders.committedTimberHeaders()
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    async refreshTracts () {
      this.committedTimber = await this.fetchCommittedTimber({
        statuses: this.committedTimberFilter.status,
        tractTypes: this.committedTimberFilter.tractType,
        tractForesters: this.committedTimberFilter.tractForester,
        teams: this.committedTimberFilter.team,
        myTracts: this.committedTimberFilter.myTracts
      })
      this.applyButtonDisabled = true
    },

    ignoreFilter (filter) {
      return (filter.tractType.length === 0 && filter.status.length === 0)
    },

    applyFilters (filters) {
      this.filters = filters
    },

    getFilterChipText (key, filter) {
      switch (key) {
        case 'status':
          return filter.map(f => TractStatus[f].name).join(', ')
        case 'tractType':
        case 'tractForester':
        case 'team':
          return filter.map(val => val.name).join(', ')
        case 'myTracts':
        default:
          return ''
      }
    },

    removeFilterField (fieldKey) {
      let removedValue = []
      switch (fieldKey) {
        case 'myTracts':
          removedValue = false
          break
        case 'status':
        case 'tractType':
        case 'tractForester':
        case 'team':
        default:
          removedValue = []
          break
      }

      const f = {
        ...this.committedTimberFilter,
        [fieldKey]: removedValue
      }

      this.setCommittedTimberFilter(f)
    },

    downloadCSV () {
      const csvString = this.generateCsvString(this.committedTimber, [
        { label: 'tract', value: ct => ct.tractName },
        { label: 'type', value: ct => ct.tractType },
        { label: 'team', value: ct => ct.teamName },
        { label: 'forester', value: ct => ct.tractForester },
        { label: 'loggingAccessibility', value: ct => ct.tractLoggingAccessibility },
        { label: 'cruisedValue', value: ct => ct.cruisedValue },
        { label: 'harvestedTons', value: ct => ct.harvestedTons },
        { label: 'expectedTons', value: ct => ct.expectedTons },
        { label: 'remainingTons', value: ct => ct.remainingTons },
        { label: 'rpi', value: ct => ct.rpi },
        { label: 'dbh', value: ct => ct.dbh },
        { label: 'age', value: ct => ct.age }

      ])
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Committed-Timber-${moment().format()}.csv`)
    },

    openDetailDialog (tract) {
      this.focusedTractId = tract.tractId
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    editTract () {
      const routerJump = new RouterJump('Tickets', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTractId = null
    },

    myTractsClicked () {
      this.setCommittedTimberFilter({
        ...this.committedTimberFilter,
        myTracts: !this.committedTimberFilter.myTracts
      })
    },

    getTeamTooltip (tractUsers) {
      return tractUsers
        .toString()
        .replaceAll(',', '\n')
    }
  }

}
</script>
