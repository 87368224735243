<template>
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="6">
      <v-card class="fill-height">
        <v-card-title class="subtitle-1 mt-n1" >
          <span>{{$t('generalInformation')}}</span>
          <v-spacer></v-spacer>
            <Icon
            v-if="tract.requiresExt1"
            icon="mdi-pound"
            margin=""
            :tooltipText="$t('requiresExt1')"/>
            <Icon
            v-if="tract.requiresExt2"
            icon="mdi-pound"
            margin=""
            :tooltipText="$t('requiresExt2')"/>
        </v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container data-testid="general-information-tract-detail" fluid>
            <v-row no-gutters v-for="row in generalInformationRows" :key="row.key">
              <v-col cols="6" xs="6" sm="5" >
                {{$t(row.key)}}:
              </v-col>
              <v-col :style="infoCardRightColumn">
                <AccountName
                v-if="row.key === 'defaultLogger' && tract && tract.loggerAccountName"
                :accountId="tract.loggerAccountId"
                :certificationStatus="tract.loggerAccountCertificationStatus"
                color="grey darken-4"
                >
                  {{tract.loggerAccountName}}
                </AccountName>
                <span v-else-if="row.key === 'defaultLogger'">
                  {{$t('notSpecified')}}
                </span>
                <span v-else-if="row.key === 'precipitations24H' && tract.rainfallDataUpdatedOn">
                  {{ tractRainfallString(tract) }}
                </span>
                <span v-else-if="row.key === 'precipitations24H'">
                  {{ $t('precipitationDataUnavailable') }}
                </span>
                <span v-else>
                  {{row.value}}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="6">
      <v-card class="fill-height">
        <v-card-title class="subtitle-1 mt-n1" >
          <span>{{$t('metrics')}}</span>
        </v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container data-testid="metrics-tract-detail" fluid>
            <v-row no-gutters v-for="row in metricsRows" :key="row.key">
              <v-col cols="6" xs="6" sm="5" >
                {{$t(row.key)}}:
              </v-col>
              <v-col cols="6" xs="6" sm="7" :style="infoCardRightColumn">
                {{row.value}}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" :lg="tract.users.length > 0 ? 6 : 12" v-if="tract.tractCertifications.length > 0">
      <v-container data-testid="certifications-tract-detail" fluid>
        <v-row dense>
          <v-col cols="12">
            <span class="subtitle-1 black--text">
              {{$t('certifications')}}
            </span>
          </v-col>
          <v-col cols="12">
            <v-chip class="mb-1 mr-1" v-for="(certification) in sortedCertifications" :key="certification.certificationId" color="secondary">
              {{certification.name}}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" :lg="tract.tractCertifications.length > 0 ? 6 : 12" v-if="tract.users.length > 0">
      <v-container fluid>
        <v-row dense align="center">
          <v-col cols="auto">
            <span class="subtitle-1 black--text" cols="auto">
              {{$t('team')}}
            </span>
          </v-col>
          <v-col>
            <v-chip small>{{tract.teamName}}</v-chip>
          </v-col>
          <v-col cols="12">
            <v-chip class="mb-1 mr-1" v-for="(user) in tract.users" :key="user.applicationUserId" color="secondary">
              <Icon v-if="user.applicationUserId === tract.foresterUser?.applicationUserId" icon="mdi-forest" iconColor="white" :tooltipText="$t('forester')"/>
              {{user.name}}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" :lg="panelSizeForLg" v-if="landowners.length > 0">
      <v-expansion-panels flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header style="border-width: 0px;">
            <v-row dense class="subtitle-1 mt-n1" style="flex-wrap: nowrap; overflow: hidden; text-wrap: nowrap;">
              <v-col>
                <span> {{$t('landowners')}} </span>
              </v-col>
              <v-col cols="auto">
                <span> {{ landownersPanelString }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
            mobile-breakpoint="396"
            class="mt-4"
            hide-default-footer
            :items-per-page="-1"
            :items="landowners"
            :headers="landownerHeaders"
            sort-by="ownership"
            sort-desc
            dense>
              <template #item.name="{item}">
                <v-row no-gutters align="center">
                  {{item.accountName}}
                </v-row>
              </template>
              <template #item.ownership="{item}">
                <span>{{formatPercentage(item.ownership)}}</span>
              </template>
              <template #item.isDefault="{item}">
                <v-icon color="black" v-if="item.isDefault">
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" :lg="panelSizeForLg" v-if="tract.loggers.length > 0">
      <v-expansion-panels flat tile>
        <v-expansion-panel >
          <v-expansion-panel-header style="border-width: 0px;">
            <v-row dense class="subtitle-1 mt-n1" style="flex-wrap: nowrap; overflow: hidden; text-wrap: nowrap;">
              <v-col>
                <span> {{$t('loggers')}} </span>
              </v-col>
              <v-col cols="auto">
                <span> {{ loggersPanelString }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
            mobile-breakpoint="396"
            class="mt-4"
            hide-default-footer
            :items-per-page="-1"
            :items="tract.loggers"
            v-if="tract.loggers"
            data-testid="loggers-tract-detail"
            :headers="loggerHeaders"
            dense>
              <template #item.name="{item}">
                <v-row no-gutters align="center">
                  <Icon
                  v-if="item.isDefault"
                  icon="mdi-star"
                  iconColor="rgba(0,0,0,.87)"
                  tooltipColor="black"
                  :tooltipText="$t('defaultLogger')"
                  />
                  <AccountName
                    :accountId="item.accountId"
                    :certificationStatus="item.certificationStatus"
                  >
                    {{item.name}}
                  </AccountName>
                </v-row>
              </template>
              <template #item.code="{item}">
                <span>{{item.code !== '' ? item.code : 'N/A'}}</span>
              </template>
              <template #item.isDefault="{item}">
                <v-icon color="black" v-if="item.isDefault">
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { formatMoney, numberWithCommas, formatPercentage } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import moment from 'moment'
export default {
  name: 'TractInformation',

  props: {
    tract: {
      type: Object,
      required: true
    },
    landowners: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountName: () => import('@/components/account/AccountName.vue')
  },

  computed: {
    isStumpage () {
      return this.categoryFromInt(this.tract.type.category) === this.$t('stumpage')
    },
    costString () {
      if (this.tract.cost <= 0) return this.$t('notSpecified')
      return this.formatMoney(this.tract.cost)
    },

    advanceBalString () {
      return this.formatMoney(this.tract.advanceBalance)
    },

    mapAndParcelNumber () {
      if (!this.tract.mapAndParcelNumber) return this.$t('notSpecified')
      return this.tract.mapAndParcelNumber
    },

    tractLocationText () {
      const { countrySubdivision: state, countrySecondarySubdivision: county } = this.tract.spot
      if (state && county) {
        return `${county}, ${state}`
      }
      return undefined
    },

    cutoutPercentage () {
      if (this.tract.expectedTons <= 0) return this.$t('notAvailable')
      const { harvestedTons, expectedTons } = this.tract
      const percentage = ((harvestedTons / expectedTons) * 100).toFixed(2)
      return `${percentage}%`
    },

    sortedCertifications () {
      const certifications = this.tract.tractCertifications
      return certifications.sort((a, b) => a.name.localeCompare(b.name))
    },

    loggerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('code'),
          value: 'code',
          align: 'center'
        }
      ]
    },

    landownerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'accountName'
        },
        {
          text: this.$t('ownership'),
          value: 'ownership',
          align: 'right'
        }
      ]
    },

    teamHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        }
      ]
    },

    infoCardRightColumn () {
      return this.$vuetify.breakpoint.xs ? 'text-align:right;' : ''
    },

    generalInformationRows () {
      const tract = this.tract
      const ns = this.$t('notSpecified')
      const na = this.$t('notAvailable')
      return [
        { key: 'type', value: tract.type.name },
        { key: 'category', value: this.categoryFromInt(tract.type.category) },
        { key: 'defaultLogger', value: tract.loggerAccountName },
        { key: 'forester', value: tract.foresterUser?.name ?? ns },
        { key: 'hauler', value: tract.haulerAccount ? tract.haulerAccount : ns },
        { key: 'consultingForester', value: tract.consultingForesterAccount ? tract.consultingForesterAccount : ns },
        { key: 'supplier', value: tract.supplierAccount ? tract.supplierAccount : ns },
        { key: 'contractAccount', value: tract.contractAccount ? tract.contractAccount : ns },
        { key: 'setting', value: tract.contractSetting ? tract.contractSetting : ns },
        { key: 'businessEntity', value: tract.businessEntity ? tract.businessEntity : na }
      ]
    },

    metricsRows () {
      const tract = this.tract
      const ns = this.$t('notSpecified')
      return [
        { key: 'cost', value: this.costString },
        { key: 'advanceBalance', value: this.advanceBalString },
        { key: 'harvested', value: numberWithCommas(tract.harvestedTons, 3) },
        { key: 'expected', value: numberWithCommas(tract.expectedTons, 3) },
        { key: 'cutoutPercentageLong', value: this.cutoutPercentage },
        { key: 'harvestPeriod', value: `${utcToLocalDate(tract.harvestStartDate)} - ${utcToLocalDate(tract.harvestEndDate)}` },
        { key: 'purchaseDate', value: utcToLocalDate(tract.purchaseDate) },
        { key: 'bondBalance', value: formatMoney(tract.bondBalance) },
        { key: 'rpi', value: tract.rpi || ns },
        { key: 'dbh', value: tract.dbh || ns },
        { key: 'age', value: tract.age || ns }
      ]
    },

    landownersPanelString () {
      return `${this.landowners.reduce((a, lo) => { return lo.ownership > a.ownership ? lo : a }).accountName}${this.landowners?.length > 1 ? ', ' + this.$t('nMore', { n: this.landowners.length - 1 }) : ''}`
    },

    loggersPanelString () {
      return `${this.tract.loggers.find(l => l.isDefault)?.name ?? this.tract.loggers[0]?.name}${this.tract.loggers.length > 1 ? ', ' + this.$t('nMore', { n: this.tract.loggers.length - 1 }) : ''}`
    },

    panelSizeForLg () {
      const show = this.numPanelsShowing
      return show === 2 ? 6
        : 12
    },

    numPanelsShowing () {
      let show = 0
      const showLandowners = !!this.landowners && this.landowners.length > 0
      const showLoggers = !!this.tract.loggers && this.tract.loggers.length > 0

      if (showLandowners) show++
      if (showLoggers) show++

      return show
    }
  },

  methods: {
    formatMoney,
    formatPercentage,
    numberWithCommas,
    utcToLocalDate,
    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    tractRainfallString () {
      const lastUpdated = moment.utc(this.tract.rainfallDataUpdatedOn).local().format('L LT')
      return `${this.tract.singleDayRainfallInches}" (${this.$t('lastUpdatedX', { x: lastUpdated })})`
    }
  }
}
</script>
